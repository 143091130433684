import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="ai--global-assistant-panels"
export default class extends Controller {
  static targets = ["thread", "greetings", "suggestions", "landingSuggestions", "history", "tips", "container"]

  threadTarget: HTMLElement
  greetingsTarget: HTMLDivElement
  suggestionsTarget: HTMLElement
  landingSuggestionsTarget: HTMLDivElement
  historyTarget: HTMLElement
  tipsTarget: HTMLElement
  containerTarget: HTMLElement

  panels: HTMLElement[]
  activePanel: HTMLElement
  positions: Map<HTMLElement, number>

  connect() {
    this.panels = [
      this.threadTarget,
      this.greetingsTarget,
      this.suggestionsTarget,
      this.landingSuggestionsTarget,
      this.historyTarget,
      this.tipsTarget,
    ]

    this.activePanel = this.panels.find((p) => !p.classList.contains("hidden"))

    this.positions = new Map([
      [this.threadTarget, 0],
      [this.greetingsTarget, 0],
      [this.suggestionsTarget, 0],
      [this.landingSuggestionsTarget, 0],
      [this.historyTarget, 0],
      [this.tipsTarget, 0],
    ])
  }

  togglePanel = (panel: HTMLElement) => {
    // Save the scroll position from the current panel before toggling
    this.positions.set(this.activePanel, this.containerTarget.scrollTop)

    // Toggle the panel
    this.activePanel = panel
    this.panels.forEach((p) => {
      if (p === panel) {
        p.classList.remove("hidden")
      } else {
        p.classList.add("hidden")
      }
    })

    // Restore the scroll position for the new panel
    this.containerTarget.scrollTop = this.positions.get(panel)
  }

  // Triggered from ai--global-assistant:submit
  showThread = () => {
    this.togglePanel(this.threadTarget)
  }

  // Triggered from ai--global-assistant:message-input
  toggleGreetings = (event) => {
    if (this.hasActiveThread()) return

    const content = event.detail?.content?.trim() || ""
    const greetingsActive = this.activePanel === this.greetingsTarget

    if (content.length > 0) {
      if (!greetingsActive) this.togglePanel(this.greetingsTarget)
    } else {
      this.toggleThread()
    }
  }

  toggleThread = () => {
    if (this.hasActiveThread()) {
      this.togglePanel(this.threadTarget)
    } else {
      this.togglePanel(this.landingSuggestionsTarget)
    }
  }

  toggleHistory = () => {
    this.togglePanel(this.historyTarget)
  }

  toggleTips = () => {
    this.togglePanel(this.tipsTarget)
  }

  toggleSuggestions = (e) => {
    if (this.hasActiveThread()) {
      this.togglePanel(this.suggestionsTarget)
    } else {
      e.preventDefault()
      return
    }
  }

  hasActiveThread = () => {
    return this.threadTarget.querySelectorAll('turbo-frame[data-thread="message"]').length > 0
  }
}
