import { Controller } from "@hotwired/stimulus"
import { hide } from "../utils"

// Connects to data-controller="policy-check-modal"
export default class extends Controller {
  static targets = ["documentViewer"]

  documentViewerTarget: HTMLElement

  closeDocumentViewer() {
    hide(this.documentViewerTarget)
  }
}
