import { Controller } from "@hotwired/stimulus"
import { post } from "@rails/request.js"

// Connects to data-controller="supplier--timeframe-filter"
export default class extends Controller {
  static targets = [
    "customStartDateSelect",
    "customEndDateSelect",
    "timeframeFilterScope",
    "currentPreviewPane",
    "currentPreviewPaneYtd",
  ]
  static values = {
    periodsOptionsData: String,
    suppliersV2Path: String,
  }

  suppliersV2PathValue: string

  customStartDateSelectTarget: HTMLSelectElement
  customEndDateSelectTarget: HTMLSelectElement
  timeframeFilterScopeTarget: HTMLInputElement
  currentPreviewPaneTarget: HTMLDivElement
  currentPreviewPaneYtdTarget: HTMLDivElement

  onTabChangeFunction: (event) => void

  connect() {
    this.onTabChangeFunction = this.onTabChange.bind(this)
    window.addEventListener("TabsComponent:tabChanged", this.onTabChangeFunction)
  }

  disconnect() {
    window.removeEventListener("TabsComponent:tabChanged", this.onTabChangeFunction)
  }

  clearFilters(): void {
    const form = this.timeframeFilterScopeTarget.form

    if (form) {
      this.timeframeFilterScopeTarget.value = ""
      this.clearSelects()
      form.requestSubmit()
    }
  }

  async onCurrentRangeChange(event: Event) {
    const selectedValue = event.target["value"]
    this.toggleCurrentPreviewPane(selectedValue)

    await post(`${this.suppliersV2PathValue}/preview_current_timeframe`, {
      responseKind: "turbo-stream",
      body: JSON.stringify({ current_range: selectedValue }),
    })
  }

  async onPreviousRangeChange(event: Event) {
    const selectedValue = event.target["value"]

    await post(`${this.suppliersV2PathValue}/preview_previous_timeframe`, {
      responseKind: "turbo-stream",
      body: JSON.stringify({ previous_range: selectedValue }),
    })
  }

  async onNextRangeChange(event: Event) {
    const selectedValue = event.target["value"]

    await post(`${this.suppliersV2PathValue}/preview_next_timeframe`, {
      responseKind: "turbo-stream",
      body: JSON.stringify({ next_range: selectedValue }),
    })
  }

  private onTabChange(event): void {
    const value = event.detail.value

    this.timeframeFilterScopeTarget.value = value
  }

  private clearSelects(): void {
    const selects = [this.customStartDateSelectTarget, this.customEndDateSelectTarget]

    selects.forEach((select) => {
      select.tomselect && select.tomselect.clear()
      select.value = ""
    })
  }

  private toggleCurrentPreviewPane(selectedValue: string): void {
    if (selectedValue === "ytd") {
      this.currentPreviewPaneTarget.classList.add("hidden")
      this.currentPreviewPaneYtdTarget.classList.remove("hidden")
    } else {
      this.currentPreviewPaneTarget.classList.remove("hidden")
      this.currentPreviewPaneYtdTarget.classList.add("hidden")
    }
  }
}
